@font-face {
  font-family: AvenirLt;
  src: url(../../assets/fonts/Avenir_Light.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(../../assets/fonts/Typold_Light.otf);
}

.image1 {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  height: auto;
  width: 70%;
}

.access {
  position: absolute;
  top: 84%;
  width: 100%;
  text-align: center;
}

.accessText {
  position: absolute;
  top: 73%;
  font-family: TypoldLt;
  color: #99e6ff;
  width: 100%;
  text-align: center;
}

.accessTextMobile {
  position: absolute;
  top: 65%;
  font-family: TypoldLt;
  color: #99e6ff;
  width: 100%;
  text-align: center;
}

.accessInput {
  font-family: TypoldLt;
  font-size: 16pt;
  border-radius: 5px;

  /* position: relative; */
}

.accessBtn {
  margin-left: 10px;
  font-family: TypoldLt;
  font-size: 16pt;
  color: #99e6ff;
  border-radius: 5px;
  background-color: transparent;
  border: none;
}

.accessBtn:active {
  background-color: #99e6ff;
  color: #21336d;
}

.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}
