.video {
  position: absolute;
  top: 13%;
  left: 15%;
  width: 70%;
  height: auto;

  border-style: solid;
  border-width: medium;
  border-color: #ffffee;
  border-radius: 10px;
}

.image2 {
  position: absolute;
  top: 1%;
  left: 0;
  right: 0;
  margin: auto;
  height: auto;
  width: 25%;
}
