.app {
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* background-color: #21336d; */
  background-color: #1800ff;
}

.page {
  height: 100%;
  width: 100%;
}
